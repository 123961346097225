import { PrismicNextImage } from '@prismicio/next';
const NextImage = ({ ...props }) => {
  return (
    <PrismicNextImage
      {...props}
    />
  );
};

export default NextImage;
