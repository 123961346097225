import React from 'react';
import { Description } from './description.style';

const TypographyTitle = ({ children, size, fontWeight, responsiveSise }) => {
  return (
    <Description
      data-aos="zoom-in"
      size={size}
      responsiveSise={responsiveSise}
      fontWeight={fontWeight}
    >
      {children}
    </Description>
  );
};

export default TypographyTitle;
