import React from 'react';
import { Title } from './title.style';

const TypographyTitle = ({
  color,
  fontWeight,
  children,
  size,
  as = 'h2',
  responsiveSise,
}) => {
  return (
    <Title
      color={color}
      fontWeight={fontWeight}
      size={size}
      as={as}
      responsiveSise={responsiveSise}
      data-aos="zoom-in"
    >
      {children}
    </Title>
  );
};

export default TypographyTitle;
