import { saasModernTheme } from 'common/theme/saasModern';
import styled from 'styled-components';

export const Description = styled.div`
  font-family: Raleway;
  font-size: ${(props) => props.size || `${saasModernTheme.space[6]}px`};
  font-weight: ${(props) => props.fontWeight || 500};
  color: white;
  @media (max-width: 450px) {
    font-size: ${(props) =>
      props.responsiveSise || `${saasModernTheme.space[5]}px`};
  }
`;
