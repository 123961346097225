import InfoSection from 'containers/SaasModern/Info';

/**
 * @typedef {import("@prismicio/client").Content.HomeInfoSlice} HomeInfoSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<HomeInfoSlice>} HomeInfoProps
 * @param {HomeInfoProps}
 */
const HomeInfo = ({ slice }) => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <InfoSection slice={slice} />
    </section>
  );
};

export default HomeInfo;
