import styled from 'styled-components';

const StyledContainer = styled.div`
  width: 85%;
  margin: 16px auto;
  margin-top: 64px;
  margin-bottom: 64px;
  position: relative;
  @media (max-width: 1024px) {
    width: 95%;
  }
  @media (max-width: 450px) {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

export default StyledContainer;
