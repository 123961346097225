import { saasModernTheme } from 'common/theme/saasModern';
import { Box, styled } from '@mui/material';
import Link from 'next/link';

export const LinkRedirection = styled(Link)`
  border: none;
  cursor: pointer;
  font-weight: 600;
  color: #ffffff;
  font-family: Raleway, Helvetica;
  font-size: ${saasModernTheme.space[4]}px;
  margin-top: 2px;
  padding: 10px 25px;
  border-radius: 10px;
  background-color: ${saasModernTheme.colors.redSecondary};
  box-shadow: 4px 4px 0 0 rgba(16, 152, 247, 0.55);
  text-transform: uppercase;
  transform: rotateZ(2deg);
  transition: 500ms;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  text-decoration: none;
  width: auto;
  &:hover {
    transform: rotateZ(0deg);
    box-shadow: none;
  }
`;

export const LinkWrapper = styled(Box)`
  display: inline-block; /* or you can use "inline" */
`;
